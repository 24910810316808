import React from 'react'
import { BlogNode } from "./blog-types";
import { Space, Divider, Tag, Button, Typography } from "antd";
const { Text } = Typography;
import { Link } from 'gatsby';

interface BlogListProps {
    title: string,
    posts: BlogNode[]
}

function renderTags(tags: string) {
    return tags.split(",").map(tag => (
        <>
            <Tag color="volcano">{tag.trim()}</Tag>
        </>
    ));
}

function renderCategories(categories: string) {
    return categories.split(",").map(category => (
        <>
            <Tag color="geekblue">{category.trim()}</Tag>
        </>
    ));
}

function renderBlogSummary(post: BlogNode) {
    let content;
    if (post.frontmatter.quote) {
        content = (
            <>
            <blockquote key={post.id} style={{paddingBottom: "0.5rem"}}>
                <Text type="secondary">{post.frontmatter.quote}</Text>
                {post.frontmatter.quoteAuthor && (
                    <>
                    <br/><br/><b>{post.frontmatter.quoteAuthor}</b>
                    </>
                )}
            </blockquote>
            </>
        )
    } else {
        content = (
            <>
            <p>{post.frontmatter.description}</p>
            </>
        )
    }
    return content;
}
const BlogList = ({title, posts}: BlogListProps ) => {
    return (
        <div className="blog-list">
            <h1>{title}</h1>
            {
                posts.map((post, index) => {
                    const content = renderBlogSummary(post);
                    return (
                        <div key={index}>
                            <Divider />
                            <h2 style={{marginBottom: 0}}>
                                <Link to={post.fields.slug} style={{color: "#1d1d1d"}}>{post.frontmatter.title}</Link>
                            </h2>
                            <p style={{marginTop: 0}}>{post.frontmatter.author} | {post.frontmatter.date}</p>
                            {content}
                            <Button style={{background: "#1d1d1d", color: "white", margin: "1rem 0"}}>
                                <Link to={post.fields.slug}>Continue Reading</Link>
                            </Button>
                            {/* <Space>
                                {renderCategories(post.frontmatter.category)}
                                {renderTags(post.frontmatter.tags)}
                            </Space> */}
                        </div>
                    )
                })
            }
            <Divider />
        </div>
    );
}

export default BlogList;